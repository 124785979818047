import React from "react"
import { graphql, Link } from "gatsby"
import VideoList from "../../components/VideoList"

const DessinsAnimesGratuits = props => {
    const url = "https://cinetimes.org/collections/westerns-gratuits/"
    const edges = props.data.allVideo.edges
    const title = `${edges.length} Westerns gratuits à voir streaming`
    const description = `Liste de ${edges.length} westerns complets en Français, qui plaira à tous les fous de la gâchette. Cette liste est automatiquement mise à jour pour refléter les modifications, ajouts et retraits. Les films viennent principalement de archive.org, mais aussi de YouTube, Dailymotion et Vimeo.`
    return (
        <VideoList url={url} title={title} description={description} edges={edges} similar={
            <>
                <Link to="/blog/films-gratuits/">Films gratuits</Link>, <Link to="/collections/vieux-films-gratuits/">Vieux films gratuits</Link>, <Link to="/collections/documentaires-gratuits/">Documentaires gratuits</Link>
            </>
        }/>
    )
}

export default DessinsAnimesGratuits

export const query = graphql`
  query {
    allVideo(filter: {video_taken_down: {eq: false}, movie_genres: {in: "Western" }}, sort: {fields: video_viewcount, order: DESC} ) {
      edges {
        node {
          video_id
          video_subtitles
          video_audio
          video_title
          translation_synopsis
          video_taken_down
          translation_slug
          translation_title
          movie_genres
          translation_poster {
              url
          }
        }
      }
    }
  }
`

